

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselImgwithText.sass";
import Assets from "../../assets/Assets";
import "./Carsl.css"
import { useNavigate } from "react-router-dom";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img src={Assets.Arrow2} alt="arrow-2" style={{ width: "40px" }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={`${className} custom-arrow`}>
      <img src={Assets.Arrow1} alt="arrow-1" style={{ width: "40px" }} />
    </div>
  );
}

const CarouselImgwithText = () => {
  const navigate = useNavigate();
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    autoplay: false,
    autoplaySpeed: 3000,
  };

  const cards = [
    {
      id: 1,
      icon: Assets.vanm1,
      title: "Vannamei​",
      content: "(Litopenaeus Vannamei)​",
    },
    {
      id: 2,
      icon: Assets.seaw1,
      title: "Sea White Prawn",
      content: "(Penaeus indicus)​",
    },
    {
      id: 3,
      icon: Assets.seatiger,
      title: "Sea Tiger",
      content: "(Penaeus Monodon) ​",
    },
    {
      id: 4,
      icon: Assets.blk1,
      title: "Black Tiger",
      content: "(Penaeus Monodon)​",
    },
    // {
    //   id: 5,
    //   icon: Assets.flowr2,
    //   title: "Flower Prawn",
    //   content: "(Penaeus semisulcatus)​",
    // },
    {
      id: 5,
      icon: Assets.flowr2,
      title: "Flower Prawn",
      content: "(Penaeus semisulcatus)​",
    },
    {
      id: 6,
      icon: Assets.fresh1,
      title: "Fresh Water Scampi",
      content: "(Macrobrachium rosenbergii)​",
    },
    {
      id: 7,
      icon: Assets.kara1,
      title: "Poovalan -Karikadi ",
      content: "(Metapenaeus Dobsoni - Paraenaeopsis stylifera)​",
    },
    // {
    //   id: 8,
    //   title: "CEPHALOPODS ",
    //   icon: Assets.p9,
    //   content: "(Metapenaeus Dobsoni - Paraenaeopsis stylifera)​",
    // },
    // {
    //   id: 9,
    //   title: "SQUID ",
    //   content: "(Loligo Sp)​",
    //   icon: Assets.squid,
    // },

    // {
    //   id: 10,

    //   title: "CUTTLEFISH ",
    //   icon: Assets.cuttlefish,
    //   content: "(Sepia Sp​)"
    // },


    // {
    //   id: 11,
    //   title: "OCTOPUS ",
    //   icon: Assets.octopus,
    //   content: "(Octopus membranes​)"
    // },

    {
      id: 12,
      title: "Blue Swimming Crab",
      icon: Assets.blueswimming,
      content: "(Portunus pelagicus)"
    },


    {
      id: 13,
      title: "SEER FISH",
      icon: Assets.p9,
      content: "(Scomberomus)"
    },

    {
      id: 14,
      title: "Indian Mackerel",
      icon: Assets.indianmark,
      content: "(Rastrelliger kanagurta)"
    },

    {
      id: 15,
      title: "Horse Mackerel",
      icon: Assets.p9,
      content: "(Megalapsis cordyla)"
    },
    {
      id: 16,
      title: "Emperor",
      icon: Assets.p9,
      content: "(Lutjanus sebae)"
    },
    {
      id: 17,
      title: "Barracuda",
      icon: Assets.bercudda,
      content: "(Spyrenea Jello)"
    },
    {
      id: 18,
      title: "CEPHALOPODS SQUID",
      icon: Assets.squid,
      content: "(Loligo Sp)"
    },
    {
      id: 19,
      title: "CEPHALOPODS CUTTLEFISH ",
      icon: Assets.cuttlefish,
      content: "(Sepia Sp)"
    },
    {
      id: 20,
      title: "CEPHALOPODS OCTOPUS ",
      icon: Assets.octopus,
      content: "(Octopus membranes)"
    }

  ];

  const goToDetailPage = (card) => {
    navigate(`/products/details`, { state: { id: card.id } });
  };

  return (
    <div className="card-carousel-container-text">
      <Slider {...settings}>
        {cards.map((card) => (
          <div
            key={card.id}
            className="card-container-text-new"
            onClick={() => goToDetailPage(card)}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-content-text-holder">
              <img style={{ width: "100%", height: "280px"}} src={card.icon} alt={card.id} />

              <div className="overlay">
                <span className="span-heading-text" >
                  {card.title}
                </span>
                <span className="span-sub-heading-text">
                  {card.content}
                </span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselImgwithText;