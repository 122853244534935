import React from "react";
import CarouselImgwithText from "../../../components/CarouselImgwithText/CarouselImgwithText";

const BestSelers = () => {
  return (
    <div className="products-1">
      <div className="container-poducts">
        <div className="container-sub-humble">
          <span className="text-title">Our Best Sellers</span>
        </div>
        <div className="category-container-2">
          <CarouselImgwithText />
        </div>
      </div>
    </div>
  );
};

export default BestSelers;
