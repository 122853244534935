import React from "react";
import Meta from "../../components/metaData/Meta";
import "./About.sass";
import HumbleBegining from "./HumbleBegining/HumbleBegining";
import Expertise from "./Expertise/Expertise";
import WeOffer from "./WeOffer/WeOffer";
const About = () => {
  return (
    <div className="whole-container">
      <Meta
        title="About Page Title"
        description="Description of the About page"
      />
      <div class="background-container">
        <HumbleBegining />
      </div>
      <div className="expertise-container">
        <Expertise />
      </div>
      <div className="weoffer-container">
        <WeOffer />
      </div>
    </div>
  );
};

export default About;
