import React from "react";
import "./OurService.sass";
import Assets from "../../assets/Assets";

const OurService = () => {
  const cards = [
    {
      id: 1,
      icon: Assets.MarketInsight,
      title: "Market Insights",
      content:
        "Stay ahead of industry trends and opportunities with our in-depth market analysis. We provide valuable insights into consumer preferences, competitor strategies and emerging market dynamics to inform your business decisions effectively.​​",
    },
    {
      id: 2,
      icon: Assets.Service2,
      title: "Sourcing Excellence",
      content:
        "Benefit from our extensive network of trusted suppliers worldwide. We meticulously vet and select partners to ensure the highest quality products at competitive prices, tailored to your specific requirements.​",
    },
    {
      id: 3,
      icon: Assets.Service4,
      title: "Strategic Price Negotiation",
      content:
        "Maximize cost efficiencies and optimize your procurement strategy with our skilled negotiation tactics. We leverage our industry expertise and market knowledge to secure favorable pricing terms without compromising on product quality.​​​",
    },
    {
      id: 4,
      icon: Assets.Service3,
      title: "Shipment Oversight",
      content:
        "Enjoy peace of mind with our comprehensive shipment oversight services. From coordinating logistics to tracking deliveries, we manage the entire shipping process to ensure timely arrivals and minimize disruptions.​",
    },
    {
      id: 5,
      icon: Assets.Service5,
      title: "Processor Accreditation",
      content:
        "Partner with accredited processors renowned for their commitment to excellence and food safety standards. We conduct thorough assessments and audits to verify compliance with industry regulations, safeguarding the integrity of your supply chain.​",
    },
    {
      id: 6,
      icon: Assets.Service6,
      title: "Buying Strategy Development",
      content:
        "Collaborate with us to develop a strategic buying plan tailored to your business objectives. We analyze market trends, demand forecasts, and supply chain dynamics to optimize purchasing decisions and drive sustainable growth.​​",
    },
    {
      id: 7,
      icon: Assets.Service7,
      title: "Quality Control: Safety Assurance",
      content:
        "Uphold the highest standards of product quality and safety with our rigorous quality control measures. We implement robust inspection protocols and compliance checks throughout the supply chain to ensure adherence to regulatory requirements and protect your brand reputation.​​",
    },
    {
      id: 8,
      icon: Assets.Service8,
      title: "Packaging and Labelling Support",
      content:
        "Stand out in the market with visual and consumer appeal of your products with our expert packaging and labeling solutions. We offer creative design services, sustainable packaging options and compliance guidance to meet regulatory standards.​​",
    },
    {
      id: 9,
      icon: Assets.Service9,
      title: "Logistics and Documentation",
      content:
        "Streamline your logistics operations and documentation processes with our efficient management solutions. From customs clearance to documentation accuracy, we handle every aspect of logistics to ensure seamless supply chain operations and compliance with international trade regulations.​​",
    },
  ];
  return (
    <div className="services-0">
      <div className="services-1">
        {/* <span className="text-title">Our Services</span> */}
        <img
          src={Assets.ServiceBanner}
          alt="ServiceBanner"
          className="services-1-img"
        />
        <div className="services-1-cards">
          <div className="services-card">
            {cards.map((card) => (
              // services-1-card-container-new
              <div key={card.id} className="services-1-card-container-new col-lg-4 col-12 col-md-6">
                <div className="services-1-card">
                  <div className="services-1-card-content-holder">
                    <img src={card.icon} alt={card.id} />
                    <span className="services-1-span-heading">
                      {card.title}
                    </span>
                    <span className="services-1-span-desc">{card.content}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurService;
