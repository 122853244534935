import React, { useState } from "react";
import Modal from "react-modal";
import "./Contact.css";
import Container from "@mui/material/Container";
import Assets from "../../assets/Assets";
import SecondaryButton from "../../components/Button/SecondaryButton/SecondaryButton";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const ContactUsPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const openModal = () => setModalIsOpen(true);

  const closeModal = () => setModalIsOpen(false);

  // Handle form input changes
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  // Validation for email and mobile
  const validateForm = () => {
    const { mobile, email } = formValues;
    let formErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]+$/;

    if (!mobileRegex.test(mobile)) {
      formErrors.mobile = "Please enter a valid mobile number.";
    }

    if (!emailRegex.test(email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation
    if (validateForm()) {
      const apiUrl = "https://ssbandco.net/api/send-mail";
      const formData = new FormData();
      formData.append("name", formValues.name);
      formData.append("mobile", formValues.mobile);
      formData.append("email", formValues.email);
      formData.append("message", formValues.message);
      formData.append("to", "Info@ssbandco.net"); 

      fetch(apiUrl, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Form submitted successfully:", data);
          openModal(); // Open success modal
          setFormValues({
            name: "",
            mobile: "",
            email: "",
            message: "",
          });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    }
  };

  return (
    <>
      <div>
        <img src={Assets.conatctban} className="banercont" alt="Contact Banner" />
      </div>
      <div className="contact-5">
        <div className="contactusm">
          <h3 className="conatcthti">Contact Us</h3>
          <div className="sncnm">
            <Container>
              <div className="row">
                <div className="col-lg-6">
                  <div className="footer-left-content">
                    <img src={Assets.Logo} alt="logo" className="footer-logo" />
                    <span className="footer-left-span-content">
                      SSB & Co. casts a distinguished presence in the global marketplace, 
                      principally serving as an eminent buyer's representative within the Frozen Seafood industry.
                    </span>
                    <div className="footer-social-medias">
                      <div className="social-medias-container">
                        <div>
                          <img src={Assets.Email} alt="email" />
                        </div>
                        <span className="social-medias-span">
                          <a href="mailto:info@ssbandco.net">info@ssbandco.net</a>
                        </span>
                      </div>
                      <div className="social-medias-container">
                        <div>
                          <img src={Assets.Phone} alt="phone" />
                        </div>
                        <span className="social-medias-span">
                          <a href="tel:+918056777546">M: +91 8056 777 546 | T: +91 44 26267317</a>
                        </span>
                      </div>
                      <div className="social-medias-container">
                        <div>
                          <img src={Assets.Location} alt="location" />
                        </div>
                        <span className="company-address2 social-medias-span">
                          A: No.20 (62/A),AA 2nd Street, Anna Nagar, Chennai - 600040.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 calb">
                  <div className="contact-form">
                    <h2 className="request-form-titles">Request for Call Back</h2>
                    <div className="footer-forms">
                      <div className="input-group c">
                        <label className="input-group__label c" htmlFor="name">Name</label>
                        <input
                          className="input-group__input c"
                          type="text"
                          name="name"
                          value={formValues.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-group c">
                        <label className="input-group__label c" htmlFor="mobile">Mobile Number</label>
                        <input
                          className="input-group__input c"
                          type="text"
                          name="mobile"
                          value={formValues.mobile}
                          onChange={handleChange}
                        />
                        {errors.mobile && <span className="error-text">{errors.mobile}</span>}
                      </div>
                      <div className="input-group c">
                        <label className="input-group__label c" htmlFor="email">Email ID</label>
                        <input
                          className="input-group__input c"
                          type="text"
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                        />
                        {errors.email && <span className="error-text">{errors.email}</span>}
                      </div>
                      <div className="input-group c">
                        <label className="input-group__label c" htmlFor="message">Message</label>
                        <textarea
                          className="input-group__input c"
                          name="message"
                          value={formValues.message}
                          onChange={handleChange}
                          rows={7}
                        />
                      </div>
                      <div className="button-container">
                        <SecondaryButton type="submit" onClick={handleSubmit}>Send Message</SecondaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.388756304531!2d80.27072151516064!3d13.082680390778615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265a300000001%3A0x0!2sNo%204%2C%20abc%20Nagar%2C%203rd%20street%2C%20abc%20colony%2C%20Chennai%20-%20600021!5e0!3m2!1sen!2sin!4v1654556475643!5m2!1sen!2sin"
                  width="600"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  className="ifrmap"
                  loading="lazy"
                  title="Google Map"
                ></iframe>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <h2>Thank you for contacting us!</h2>
        <p>We have received your message and will get back to you shortly.</p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
};

export default ContactUsPage;