import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Gallery.css';
import Assets from "../../assets/Assets";
import GalleryFirstSection from './GalleryFirstSection';

const images = [
    Assets.image1,
    Assets.image2,
    Assets.image3,
    Assets.image4,
    Assets.image5,
    Assets.image6,
    Assets.image7,
    Assets.image8,
    Assets.image9,
    Assets.image10,
    Assets.image11,
    Assets.image12
];

const Gallery = () => {
    const settings = {
        // dots: true,
        infinite: true,
        // autoplay:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div>
        <div className='background-container-products'>
            <div className="product-container">
                <div style={{textAlign:'center'}}>
                <span className="text-title">Gallery</span>
                </div>
                <div className="desc-container">
                    <span className="product-desc">
                        We invite you to take a behind-the-scenes
                        look at our vibrant world of the seafood industry. Explore captivating snapshots of our team in action,
                        from harvesting the freshest catch to expertly packaging each product with care. Join us on this visual journey as
                        we showcase the passion and commitment that keeps us and our industry moving forward.
                    </span>
                </div>
            </div>
            <div className='container'>
            
            <Slider {...settings}>
                {images.map((image, index) => (
                    //   <div key={index} className="image-container">
                    //     <img src={image} alt={`Gallery ${index + 1}`} className="gallery-image"/>
                    //   </div>
                    <GalleryFirstSection />
                ))}
            </Slider>
            </div>
        </div>
        </div>

    );
}

export default Gallery;
